<template>
  <CustomHeader class="min-h-screen" :color="storeTypeSelected?.color">
    <template #header>
      <div class="h-full w-full relative overflow-hidden">
        <span
          class="absolute -translate-y-1/2 translate-x-1/2 top-1/2 right-1/2 text-lg font-bold"
        >{{ t(storeTypeSelected?.name ?? '') }}</span>
        <PlacesDialouge
          :force-selector="true"
          class="absolute text-sm -translate-y-1/2 top-1/2 rtl:left-2 ltr:right-2"
        />
      </div>
    </template>
    <template #body="{ small }">
      <div class="mevcut-container min-h-screen mt-5">
        <div v-if="!small" class="relative" style="min-height: 50px">
          <div
            class="mevcut-title absolute whitespace-nowrap left-1/2 -translate-x-1/2"
          >
            <span>{{ t(storeTypeSelected?.name ?? '') }}</span>
          </div>

          <PlacesDialouge :force-selector="true" :hide-activator="true" />
        </div>

        <div v-if="!enableForceSelectedCity">
          <div class="border-b border-text_color mx-auto mb-3 max-w-md">
            <RikazSearchField
              :value="storeName"
              class="ltr:mr-3.5 rtl:ml-3.5"
              :placeholder="t('what_are_you_looking_for')"
              :input-class="'w-full '"
              :search-size-icon="20"
              prefix
              close-class="text-mevcut-light w-5 h-5 cursor-pointer"
              @search-term="filterStoreName"
            />
          </div>
          <StoreTypesCategoryList :categories="categories" class="mb-3" />
          <StoresGrid
            :key="`StoresGridDelivery ${selectedCityId}`"
            :fetch-route="storesRoutes.getStoresByMaterialName"
            class="mb-5"
            :enable-cache="false"
            :is-delivering="true"
            :fetch-params="{
              orderBy: 'id',
              store_type_id: storeTypeSelected?.id,
              city_id: selectedCityId
            }"
            :with-query-params="true"
            @get-first-store="getFirstStore($event)"
          />
        </div>
      </div>
    </template>
  </CustomHeader>
</template>

<script setup lang="ts">
import { getStoreTypeDeliveryById } from '~/components/delivery/StoreTypes/StoresTypeLevels'
import getAllRoutes from '~/composables/AppApiRoutes'
import { usePlacesStore } from '~/store/places'
const storesRoutes = getAllRoutes().storesRoutes
const { url } = useDomainHost()
const { storeTypes } = useStoreTypes()
const { t } = useI18n()

const route = useRoute()
const storeTypeSelected = computed(() => {
  return getStoreTypeDeliveryById(route.params.id.toString())
})

function filterStoreName(value: string) {
  storeName.value = value
  useQueryNavigation({ name: value })
}
const categories = computed(
  () =>
    storeTypes.value?.find(
      (el) => el.id.toString() === route.params.id?.toString()
    )?.parent_categories
)

const selectedCityId = computed(() => {
  return usePlacesStore().cityId
})
const enableForceSelectedCity = computed(() => {
  return !usePlacesStore().disableSelectedCityForce()
})
const router = useRouter()
const localePath = useLocalePath()
function getFirstStore(store: Store) {
  if (storeTypeSelected.value?.searchPageType) {
    router.replace(localePath({ path: `/delivering/stores/${store.id}` }))
  }
}
const storeName = ref(route.query.name as string)
useServerSeoMeta(
  useOgMeta(
    url + '/delivering/storeTypes/' + route.params.id,
    t(storeTypeSelected.value?.name ?? '')
  )
)

useHead({
  title: t(storeTypeSelected.value?.name ?? ''),
  meta: [
    {
      hid: 'description',
      name: 'description'
    }
  ],
  link: [useSeoCanonical(url + '/delivering/storeTypes/' + route.params.id)],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('at_yours'), url + '/delivering'),
      useSeoBreadcrumbItem(3, t('storeTypes'), url + '/delivering/storeTypes'),
      useSeoBreadcrumbItem(
        4,
        t(storeTypeSelected.value?.name ?? ''),
        url + '/delivering/storeTypes/' + route.params.id
      )
    ])
  ]
})
</script>
